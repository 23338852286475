/** @jsx jsx */
import React, { useEffect } from "react"
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import chunk from "lodash/chunk"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"
import get from "lodash/get"

import { useMedia } from "react-use"

import {
  getContentfulPageSectionListData,
  renderLineBreak,
  findBySlug,
  mediaQueryList,
} from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import CTA from "../components/cta"
import Hero from "../components/hero"
import FeaturedProjects from "../components/featured-projects"
import DesignPartnerCard from "../components/design-partner-card"
import Page from "../components/page"
import PageLink from "../components/page-link"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"
import ModelSlider from "../components/model-slider"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"

const DesignPartnersPage = ({ data }) => {
  const [isReady, setIsReady] = React.useState(false)
  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  React.useEffect(() => {
    if (checkIfWindow) {
      window.location.href = "/"
      setIsReady(true)
    }
  }, [])

  paramsCheck();
  useEffect(() => {
    document.head.innerHTML += '<meta name="robots" content="noindex">'
  }, []);
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-design-partners-hero",
    })
    const heroHeights = [null, null, 549]

    if (!heroBladePatternData) return
    // TODO: Stacked mobile hero
    const ctaList = []

    if (heroBladePatternData.ctaTitle) {
      ctaList.push({
        link: heroBladePatternData.ctaDestination,
        type: "secondary",
        text: heroBladePatternData.ctaTitle,
      })
    }

    return (
      <Hero
        customStyle={{
          height: heroHeights,
          maxHeight: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        body={heroBladePatternData.description.description}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderDesignMattersBladePattern = () => {
    const designMattersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-design-matters",
    })

    if (!designMattersBladePatternData) return
    const description = get(
      designMattersBladePatternData,
      "contentCards[0].description"
    )
    
    return (
      <div
        sx={{
          pb: theme => theme.spacing.vertical.lg,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Container
          customStyle={{
            backgroundColor: theme => [
              theme.colors.backgrounds.primary,
              theme.colors.white,
            ],
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row>
            <Column
              size={[12, 12, 4]}
              customStyle={{
                mb: theme => [theme.spacing.vertical.sm, null],
              }}
            >
              <Text type="h2">
                {renderLineBreak(designMattersBladePatternData.title)}
              </Text>
            </Column>
            <Column size={[12, 12, 4]}>
              {description ? (
                <RichText data={description} />
              ) : (
                <Text
                  type="p"
                  customStyle={{
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                  }}
                >
                  {designMattersBladePatternData.description.description}
                </Text>
              )}
            </Column>
          </Row>
        </Container>
        <Container>
          <Row>
            {data.allContentfulDesignPartner.edges.map(designPartner => (
              <Column
                size={[12, 6, 6, 4, 4]}
                customStyle={{
                  mt: theme => theme.spacing.horizontal,
                }}
                key={kebabCase(designPartner.node.name)}
              >
                <PageLink
                  link={ (designPartner.node.slug =="plant-design-studio") ? `/${designPartner.node.slug}` : `/architects/${designPartner.node.slug}`}
                  type="internal"
                  customStyle={{ mr: -5 }}
                >
                  <DesignPartnerCard
                    background={designPartner.node.photo.file.url}
                    text={designPartner.node.name}
                  />
                </PageLink>
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  const renderSeeTheResultsBladePattern = () => {
    const seeTheResultsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-page-gallery-module",
    })

    if (!seeTheResultsBladePatternData) return

    // console.log("seeData",seeTheResultsBladePatternData,data)

    return (
      <React.Fragment>
        <Container customStyle={{ pb: theme => theme.spacing.vertical.lg }}>
          <Row>
            <Column size={[12]}>
              <Text type="h2">
                {renderLineBreak(seeTheResultsBladePatternData.title)}
              </Text>
            </Column>
            <Column size={[12]}>
              <div
                sx={{
                  width: 280,
                  mt: theme => theme.spacing.vertical.sm,
                }}
              >
                <CTA
                  link={seeTheResultsBladePatternData.ctaDestination || ""}
                  type="secondary"
                >
                  {seeTheResultsBladePatternData.ctaTitle}
                </CTA>
              </div>
            </Column>
          </Row>
        </Container>
        {/* <FeaturedProjects data={data.allContentfulProject} /> */}
        <FeaturedProjects data={seeTheResultsBladePatternData} />
      </React.Fragment>
    )
  }
  const renderRecognitionBladePattern = () => {
    const recognitionBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-recognition",
    })
    if (!recognitionBladePatternData) return

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.md }}>
        <Row>
          <Column>
            <Text type="h3" customStyle={{ width: ["360px", "390px"] }}>
              {mediaQueryMatch
                ? renderLineBreak(recognitionBladePatternData.title)
                : recognitionBladePatternData.title.replace(/(\\n)/gm, " ")}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            mt: [
              theme => theme.spacing.vertical.sm,
              theme => theme.spacing.vertical.sm,
              theme => theme.spacing.vertical.md,
            ],
            p: theme => theme.spacing.horizontal,
          }}
        >
          {/* TODO: Rename contentCards to basicCardsList after updating Contentful model*/}
          {/* TODO: make this a component */}
          {recognitionBladePatternData.contentCards.map((basicCard, idx) => {
            return (
              <Column
                customStyle={{
                  alignItems: [
                    "flex-start",
                    "flex-start",
                    "flex-start",
                    "center",
                  ],
                  mb: [
                    theme => theme.spacing.vertical.sm,
                    theme => theme.spacing.vertical.sm,
                    theme => theme.spacing.vertical.sm,
                    0,
                  ],
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, 12, null]}
                key={kebabCase(basicCard.title)}
              >
                <Flex sx={{ alignItems: "center" }}>
                  <img
                    sx={{ maxWidth: 140 }}
                    src={basicCard.desktopImage.file.url}
                    alt=""
                  />
                  {basicCard.title && (
                    <Text customStyle={{ fontWeight: "heading", ml: 3 }}>
                      {renderLineBreak(basicCard.title)}
                    </Text>
                  )}
                </Flex>
              </Column>
            )
          })}
        </Row>
      </Container>
    )
  }
  const renderLivinghomesModelsSection = () => {
    // const livinghomesModelsSectionData = getContentfulPageSectionListData({
    //   sectionList: data.contentfulPage.sectionList,
    //   sectionSlug: "page-section-livinghomes-models",
    // })

    const designerSliderData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-model-slider",
    })

    const renderContentCard = standardHome => (
      <ContentCard
        baths={standardHome.baths}
        beds={standardHome.beds}
        imageList={[standardHome.images[0]]}
        squareFootage={standardHome.squareFootage}
        title={standardHome.name}
        titleLink={
          standardHome.model
            ? {
                to: `/models/${standardHome.model.slug}`,
                type: "internal",
              }
            : null
        }
        key={standardHome.id}
      />
    )
    const getMobileCarouselContent = () => {
      const carouselContent = []

      data.allContentfulModelVariation.edges.forEach(standardHome => {
        if (standardHome.node.images && carouselContent.length < 4) {
          carouselContent.push(renderContentCard(standardHome.node))
        }
      })

      return carouselContent
    }

    const getDesktopCarouselContent = () => {
      const carouselContent = []
      chunk(
        data.allContentfulModelVariation.edges.filter(
          standardHome => standardHome.node.images
        ),
        2
      ).forEach((standardHome, index) => {
        if (standardHome.length === 2) {
          const getColumnSize = columnSize => {
            return {
              flexBasis: `${columnSize * 100}%`,
              maxWidth: `${columnSize * 100}%`,
            }
          }

          carouselContent.push(
            <div
              sx={{ display: "flex", height: "100%" }}
              key={standardHome[0].node.id}
            >
              <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
                {renderContentCard(standardHome[0].node)}
              </div>
              <div
                sx={{
                  pl: theme => theme.spacing.horizontal,
                  ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
                }}
              >
                {renderContentCard(standardHome[1].node)}
              </div>
            </div>
          )
        } else if (standardHome.length === 1 && standardHome[0].node.images) {
          carouselContent.push(renderContentCard(standardHome[0].node))
        }
      })

      return carouselContent
    }

    let livingHomeModels =
      data.allContentfulModelVariation.edges &&
      data.allContentfulModelVariation.edges.filter(ele => {
        return ele.node.type === "single-family-model" ? true : false
      })

    let GalleryDesign
    if (data.allContentfulModelGallery.edges) {
      GalleryDesign = data.allContentfulModelGallery.edges.find(
        ele => ele.node.slug === "architects-model-gallery"
      )
    }

    return (
      <Container customStyle={{ pb: theme => theme.spacing.vertical.lg }}>
        <Row>
          {/* {livinghomesModelsSectionData.titleList.map((titleData, index) => (
            <Column
              customStyle={{
                mb: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: 0 },
              }}
              size={index ? [12, 12, 8] : [12, 12, 4]}
              key={kebabCase(titleData.text)}
            >
              <Text type="h2">{renderLineBreak(titleData.text)}</Text>
            </Column>
          ))} */}
          <Column
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
              ":last-child": { mb: 0 },
            }}
          >
            <Text type="h2" customStyle={{ fontSize: [36, 36, 40] }}>
              {renderLineBreak(designerSliderData.title)}
            </Text>
          </Column>

          <Column size={[12, 12, 6]}>
            <Flex
              sx={{
                justifyContent: ["flex-start", "flex-start", "flex-end"],
                mt: theme => [theme.spacing.vertical.md],
              }}
            >
              <div>
                {designerSliderData.ctaTitle ? (
                  <CTA
                    link={designerSliderData.ctaDestination || "/"}
                    type={"secondary"}
                    linkType="internal"
                  >
                    {designerSliderData.ctaTitle}
                  </CTA>
                ) : null}
              </div>
            </Flex>
          </Column>
        </Row>
        <Row>
          {/* <Column customStyle={{ display: ["block", "block", "none"] }}>
            <Carousel type="quaternary">{getMobileCarouselContent()}</Carousel>
          </Column>
          <Column customStyle={{ display: ["none", "none", "block"] }}>
            <Carousel type="quaternary">{getDesktopCarouselContent()}</Carousel>
          </Column> */}
          {/* <ModelSlider data={{ edges: livingHomeModels ? [...livingHomeModels] : [] }} /> */}
          <ModelSlider data={GalleryDesign} />
        </Row>
      </Container>
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    isReady&&<Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderDesignMattersBladePattern()}
      {renderSeeTheResultsBladePattern()}
      {renderRecognitionBladePattern()}
      {renderLivinghomesModelsSection()}
      {renderQuote()}
    </Page>
  )
}

export default DesignPartnersPage

export const query = graphql`
  query designPartnersPageQuery {
    contentfulPage(slug: { eq: "architects" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulModelGallery {
      edges {
        node {
          id
          title
          slug
          model {
            id
            name
            model {
              id
              slug
            }
            squareFootage
            baths
            beds
            images {
              fluid {
                src
              }
            }
          }
        }
      }
    }

    allContentfulProject(
      filter: { projectTytpe: { in: ["Single Family"] } }
      limit: 6
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          projectImages {
            file {
              url
            }
            id
            title
          }
          slug
          projectTytpe
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
        }
      }
    }

    allContentfulDesignPartner {
      edges {
        node {
          name
          slug
          photo {
            file {
              fileName
              url
            }
          }
        }
      }
    }

    allContentfulModelVariation(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          baths
          beds
          id
          images {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          name
          squareFootage
          model {
            id
            slug
            designPartner {
              name
            }
          }
          createdAt
          type
        }
      }
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "design-partners-quote" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`